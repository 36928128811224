.tnb-portal-gator-scrolling-wrapper-flexbox {
    background-color: #282a35;
    display: flex;
    padding-top:20px;
    padding-bottom:20px;
    padding-left:5rem;
    flex-wrap: nowrap;
    overflow-x: auto;
    /*justify-content: center;*/
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
     display: none;
   }
}

.tnb-portal-gator-card {
  flex: 0 0 auto;
  margin: 0 15px;
}

.tnb-portal-gator-card-wrapper {
    max-width:200px;
    border-radius:2px;
}

 a.tnb-portal-gator-card:any-link,
 a.tnb-portal-gator-card:-webkit-any-link {
     text-decoration:none;
     color:white;
}

.tnb-gator-teaser-image {
    width: 200px; /* width of container */
    height: 200px; /* height of container */
    object-fit: cover;
    border: 1px solid black;
}

.tnb-portal-gator-title {
    font-size: 18px;
    font-weight: bold;
    text-decoration:none;
    margin:5px 0 0 2px;
}

.tnb-portal-gator-sub-hookline {
    font-size:14px;
    font-style: italic;
    margin:5px 0 0 2px;
}

.tnb-portal-gator-author {
    font-size:11px;
    margin:5px 0 0 0px;
}
